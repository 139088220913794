<template>
  <content-section :spacing="false">
    <div class="px-4 content-spacing">
      <title-plus
        :title="
          $t(
            'components.orgUserDetailsManagement.diveIntoTab.incomingTransaction.headline'
          )
        "
        :hidePlus="true"
        class="mb-10"
      />
    </div>
    <template>
      <FSTable
        :fst-id="fstId"
        :qso="qso"
        :endpoint="indexDataEndpoint"
        :headers="tableHeaders"
      >
        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <FSTableRow :key="itemIndex" textFallbackAlways>
              <FSTableRowItem>
                <router-link to="#" class="font-bold text-sm text-blue-600"
                  >#{{ item.id | onlyLastFive }}</router-link
                >
              </FSTableRowItem>
              <FSTableRowItem>
                <router-link to="#" class="font-bold text-sm text-blue-600">{{
                  item.from_user ? item.from_user.full_name : '--'
                }}</router-link>
              </FSTableRowItem>
              <FSTableRowItem>
                <span class="text-sm text-gray-700 font-normal">{{
                  item.created_at | friendlyDateTime
                }}</span>
              </FSTableRowItem>
              <FSTableRowItem>
                <span class="font-bold text-md"
                  >{{ getCurrencySymbol }} {{ item.amount }}</span
                >
              </FSTableRowItem>

              <FSTableRowItem>
                <span
                  v-if="item.status == 'C'"
                  class="success bg-green-500 py-1 text-white px-4 rounded"
                  >Success</span
                >
                <span
                  v-else
                  class="error bg-red-500 py-1 text-white px-4 rounded"
                  >Pending</span
                >
              </FSTableRowItem>

              <FSTableRowItem>
                <div class="flex items-center">
                  <router-link to="#" class="text-gray-500 text-lg mx-2">
                    <i class="fas fa-eye"></i>
                  </router-link>
                  <router-link to="#" class="text-gray-500 text-lg mx-2">
                    <i class="fas fa-download    "></i>
                  </router-link>
                </div>
              </FSTableRowItem>
            </FSTableRow>
          </template>
        </template>
      </FSTable>
    </template>
  </content-section>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'

import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import { AgentUserConfig } from '@/config/AgentUserConfig'
export default {
  name: 'UserIndex',

  components: {
    ContentSection,
    TitlePlus,

    FSTable,
    FSTableRow,
    FSTableRowItem,
  },
  data() {
    return {
      fstId: 'IncomingHistory',
      qso: { append: '', prepend: '?' },
      tableHeaders: [
        {
          text: this.$t(
            'components.orgUserDetailsManagement.diveIntoTab.incomingTransaction.table.columns.invoiceId'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.diveIntoTab.incomingTransaction.table.columns.transactedBy'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.diveIntoTab.incomingTransaction.table.columns.transferDate'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.diveIntoTab.incomingTransaction.table.columns.amount'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.diveIntoTab.incomingTransaction.table.columns.status'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.diveIntoTab.incomingTransaction.table.columns.invoice'
          ),
          width: '10%',
          sort: null,
        },
      ],
    }
  },
  computed: {
    indexDataEndpoint() {
      return AgentUserConfig.api.incomingHistory(this.$route.params.id)
    },
    getCurrencySymbol() {
      return this.$store.getters['auth/organizationInfo'].default_currency
        ? this.$store.getters['auth/organizationInfo'].default_currency.symbol
        : '$'
    },
  },

  async mounted() {
    window.addEventListener(this.$config.orgUser.events.refresh, () => {
      this.$store.dispatch('fsTable/fetchData')
    })
  },
}
</script>

<style></style>
